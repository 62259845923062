<template>
  <div>
    <el-row :gutter="48">
      <div style="display: flex;justify-content: space-between;padding-bottom: 12px">
        <div style="padding-left: 24px">
          <el-button type="primary" size="mini" @click="onSelectQuestion">选文章</el-button>
          <el-button type="danger" size="mini" @click="onDeleteArticleClick" :disabled="articleIds.length == 0">批量删除
          </el-button>
        </div>
        <div style="padding-right: 24px">
          <el-switch
              v-model="isSort"
              inactive-text="列表排序">
          </el-switch>
        </div>

      </div>

      <el-col class="el-col-sm-24 el-col-md-24 el-col-lg-24" style="margin-top: 20px">
        <el-form :inline="true" :model="searchCondition" class="demo-form-inline">
          <el-form-item label="标题">
            <el-input placeholder="请输入文章标题" clearable v-model="searchCondition.title"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="onResetSearchClick">重置</el-button>
          </el-form-item>
        </el-form>

        <ape-table ref="existsApeTable"
                   :data="articleAddList"
                   :columns="articleColumns"
                   :loading="loadingStatus"
                   :pagingData="existsPagingData"
                   @selectList="handleSelectionChange"
                   @switchPaging="switchPaging"
                   highlight-current-row>

          <el-table-column
              slot="first-column"
              width="80"
              type="selection"
              align="center">

          </el-table-column>

          <el-table-column
              v-if="buttonType== 'icon'"
              label="操作"
              width="200"
              fixed="right">
            <template slot-scope="scope">
              <span v-if="!isSort">
                <el-tooltip effect="dark" content="删除" placement="top-start">
                  <span>
                      <el-button type="danger"
                                 size="mini"
                                 v-if="userPermissions.indexOf('test_paper_delete') != -1"
                                 icon="el-icon-delete"
                                 @click="deleteButton(scope.row.id)"
                      ></el-button>
                  </span>
                </el-tooltip>
              </span>
              <span v-if="isSort">
                <el-tooltip effect="dark" content="置顶" placement="top-start">
                    <el-button size="mini" icon="el-icon-upload2" @click="onStickClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="置底" placement="top-start">
                    <el-button size="mini" icon="el-icon-download" @click="onUndersideClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="向上排序" placement="top-start">
                    <el-button size="mini" icon="el-icon-top" @click="onUpwardClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="向下排序" placement="top-start">
                    <el-button size="mini" icon="el-icon-bottom" @click="onDownwardClick(scope.row.id)"></el-button>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
          <el-table-column
              label="操作"
              v-if="buttonType== 'text'"
              width="200"
              fixed="right"
          >
            <template slot-scope="scope">
              <span>

                <el-tooltip effect="dark" content="删除" placement="top-start">
                  <span>
                        <el-button type="danger"
                                   size="mini"
                                   v-if="userPermissions.indexOf('test_paper_delete') != -1"
                                   icon="el-icon-delete"
                                   @click="deleteButton(scope.row.id)"
                        ></el-button>
                  </span>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
        </ape-table>

      </el-col>

    </el-row>

    <el-dialog :visible.sync="isSelectArticleDialog" :close-on-click-modal="false" width="80%" title="选择文章"
               :append-to-body="true" fullscreen style="margin: 50px" @opened="onOpenedSelectArticle"
               :destroy-on-close="true">
      <select-article ref="selectArticleRef" @dialog-confirm="handleArticleDialogConfirm"
                      @dialog-close="onSelectArticleClose"></select-article>
    </el-dialog>
  </div>

</template>

<script>
import ApeTable from '@/components/ApeTable';
import elTableInfiniteScroll from 'el-table-infinite-scroll';
import {mapGetters} from 'vuex'
import SelectArticle from "@/pages/special_topic/category/components/SelectArticle";

const NO_DEL = "no_del";
const DEL = "del";
const IS_SUCCESS = "ok";
export default {
  components: {
    ApeTable,
    SelectArticle
  },
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },
  data() {
    return {
      isSort: false,
      isSelectArticleDialog: false,
      loadingStatus: true,
      questionBankQuestionList: [],
      summaryPagingData: {
        offset: undefined
      },

      existsPagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },

      articleColumns: [
        {
          title: '序号',
          value: 'order',
          width: '80'
        },
        {
          title: 'ID',
          value: 'id',
          width: '100'
        },
        {
          title: '标题',
          value: 'title',
        },
        {
          title: '封面',
          type: 'image',
          value: 'cover_pic_url',
          width: '80'
        },
        {
          title: '缩略图',
          type: 'image',
          value: 'thumb_pic_url',
          width: '80'
        },
        {
          title: '发布时间',
          value: 'created_at',
          width: '180'
        }
      ],

      //分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },

      questionList: [],
      modelVisible: false,
      isShowCard: false,
      tableSelectQuestionList: [],
      tableSelectList: [],
      tableData: [],
      totalPage: 1,
      cacheId: "",
      //修改分数和顺序
      modifyData: {
        score: "",
        order: ""
      },
      //过滤题目数组
      checkedQuestionList: [],
      totalCount: null,
      page: 1,
      //已经新增的表
      articleAddList: [],
      cId: "",
      // //分页信息
      pagingInfo: {
        page_size: 10,
        current_page: 1
      },

      //搜索条件
      searchCondition: {},
      searchParams: {},
      isSearch: false,
      qtqId: "",
      summaryQuestionList: [],
      qbId: "",


      //选择文章对话框配置
      dialogDate: {
        visible: false,
        title: "选择文章",
        width: '50%'
      },

      articleDialogData: {
        id: ""
      },

      stcId: "",

      //选中文章
      articleIds: []
    }
  },
  mounted() {
    this.cacheId = new Date().getTime();
    // this.stcId = this.$route.params.c_id;
  },
  methods: {
    setStcId(id) {
      this.stcId = id
      this.resetArticleResourceList(DEL);
    },

    //删除已选题目
    deleteButton(id) {

      this.$confirm('是否删除该已选文章?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = {
          stc_id: this.stcId,
          ids: [id],
        };

        let info = await this.$api.deleteSpecialTopicResourceList(data);
        if (info) {
          this.$nextTick(() => {
            this.resetArticleResourceList(DEL);
            this.$message.success("删除成功");
          })
        } else {
          this.$message.error("删除失败")
        }
      })
    },

    //弹框
    onSelectQuestion() {
      // this.dialogDate.visible = true;
      // this.articleDialogData.id = this.stcId;
      // this.$nextTick(() => {
      //   this.$refs['articleDialog'].resetData();
      // })

      this.isSelectArticleDialog = true
    },
    onOpenedSelectArticle() {
      this.$refs.selectArticleRef.setId(this.stcId)
    },

    resetAll() {
      this.searchCondition = {}
      this.articleAddList = []
      this.articleIds = []
    },

    //以选文章
    async resetArticleResourceList(type) {
      this.loadingStatus = true;
      let pagingInfo = this.$refs['existsApeTable'].getPagingInfo(type);
      if (!this.stcId) {
        this.$message("分类ID不存在");
        return;
      }

      if (type === DEL) {
        pagingInfo.current_page = 1
        this.$refs['existsApeTable'].resetCurPageSize();
      }

      let data = {
        page_size: pagingInfo.page_size,
        current_page: pagingInfo.current_page,
        stc_id: this.stcId
      }
      if (this.searchCondition && this.searchCondition.title) {
        data["title"] = this.searchCondition.title
      }



      let {list, pages} = await this.$api.selectSpecialTopicCategoryResourceList(data);

      this.$nextTick(() => {

        this.articleAddList = list;

      });
      this.existsPagingData.total = pages.total;
      // this.existsPagingData.offset = pages.offset;
      this.loadingStatus = false
    },

    //切换页码
    async switchPaging() {
      this.resetArticleResourceList()
    },

    //------选择文章相关方法--------
    handleArticleDialogClose() {
      this.dialogDate.visible = false;
    },
    onSelectArticleClose() {
      this.isSelectArticleDialog = false;
    },

    //文章弹框点击
    async handleArticleDialogConfirm(formData) {
      if (formData.length <= 0) {
        this.isSelectArticleDialog = false
        return
      }

      if (!this.stcId) {
        this.$message("分类不能为空");
        return;
      }

      let params = {
        stc_id: this.stcId,
        articles: formData
      };


      let info = await this.$api.selectSpecialTopicCategoryArticle(params);
      if (info) {
        this.$message.success("添加成功");
      } else {
        this.$message.error("添加失败");
        return;
      }

      //刷新列表
      // this.$refs['articleDialog'].resetData();
      await this.resetArticleResourceList(DEL);
      this.isSelectArticleDialog = false;
    },

    //获取所选择的文章列表
    handleSelectionChange(list) {
      this.articleIds = [];
      for (let article of list) {
        this.articleIds.push(article.id);
      }
    },

    //批量删除文章
    onDeleteArticleClick() {
      if (this.articleIds.length <= 0) {
        return;
      }

      this.$confirm('是否删除已选文章?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = {
          stc_id: this.stcId,
          ids: this.articleIds
        };

        let info = await this.$api.deleteSpecialTopicResourceList(data);
        if (info) {
          this.$nextTick(() => {
            this.resetArticleResourceList(DEL);
            this.$message.success("删除成功");
          })
        } else {
          this.$message.error("删除失败")
        }
      })
    },

    onSearchClick() {
      // this.$refs['existsApeTable'].resetCurPageSize();
      this.resetArticleResourceList(DEL)
    },

    onResetSearchClick() {
      this.searchCondition = {}
      this.resetArticleResourceList(DEL)
    },

    //置顶
    async onStickClick(id) {
      let data = {
        id : id,
        type: 'top'
      }
      await this.$api.orderSpecialTopicResourceList(data)
      await this.resetArticleResourceList(DEL)
    },
    //置底
    async onUndersideClick(id) {
      let data = {
        id : id,
        type: 'bottom'
      }
      await this.$api.orderSpecialTopicResourceList(data)
      await this.resetArticleResourceList(NO_DEL)
    },
    //向上排序
    async onUpwardClick(id) {
      let data = {
        id : id,
        type: 'up'
      }
      await this.$api.orderSpecialTopicResourceList(data)
      await this.resetArticleResourceList(NO_DEL)
    },
    //向下排序
    async onDownwardClick(id) {
      let data = {
        id : id,
        type: 'down'
      }
      await this.$api.orderSpecialTopicResourceList(data)
      await this.resetArticleResourceList(NO_DEL)
    },
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
  }
}
</script>

<style lang="stylus" scoped>
.color-primary
  color #1890ff

.text-over-hide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex-layout {
  display flex
}

.margin-top-20 {
  margin-top 20px
}
</style>
<style lang="stylus" scoped>
/deep/ .el-switch__label
  color #0099ff
</style>